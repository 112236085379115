import fetch from 'isomorphic-fetch';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';

const client = new ApolloClient({
	cache: new InMemoryCache({
		resultCaching: false,
	}),
	link: new HttpLink({
		uri: 'https://sspl-api.bambit.com.pl/graphql',
		fetch,
	}),
});

export default client;
