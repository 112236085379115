exports.components = {
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-certifications-tsx": () => import("./../../../src/pages/certifications.tsx" /* webpackChunkName: "component---src-pages-certifications-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-deposits-tsx": () => import("./../../../src/pages/deposits.tsx" /* webpackChunkName: "component---src-pages-deposits-tsx" */),
  "component---src-pages-fees-tsx": () => import("./../../../src/pages/fees.tsx" /* webpackChunkName: "component---src-pages-fees-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

