export const colors = {
	white: '#ffffff',
	golden: '#E1BE86',
	grey: '#3A3A3A',
	graphite: '#2B2B2B',
};

const theme = {
	colors,
};

export type Theme = typeof theme;

export default theme;
